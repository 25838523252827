// Styles for Nodes
// -----------------------------------------------------------------------------

h1.title{
  padding: 0 0 0 1%;
  text-shadow: 0 0 5px #fff;
  //background: $main-color-shade;
  //background: -moz-linear-gradient(left, $main-color-shade 40%, $main-color-shade 16%, #fff 100%);
  background: -webkit-linear-gradient(left, $main-color-shade 10%, $main-color-shade 5%, #fff 100%);
  background: linear-gradient(to right, $main-color-shade 10%, $main-color-shade 5%, #fff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#77e5e5', endColorstr='#fdffff',GradientType=1 );
}


.field-body {
  font-size: x-large;
  /* border: 2px outset; */
  /* border-radius: 10px; */
  padding: 1%;
  border-bottom: .5vh dashed $main-color-shade ;
  border-top: .5vh dashed $main-color-shade ;
  box-shadow: inset 0 -1px 0 0 $main-color-shade, inset 0 1px 0 0 $main-color-shade, 0 1px 0 0  $main-color-shade, 0 -1px 0 0 $main-color-shade;
  margin-bottom: 1px;
}

.ds-2col-stacked > .group-left {
  width: 35%;
  float: left;
}

.ds-2col-stacked > .group-right {
  width: 65%;
  float: right;
  padding: 0 0 0 2%;
}

.ds-2col-stacked > .group-footer {

}

.call-to-action-1 {
  width: 100%;
  margin: 1% auto;
  border: 5px groove;
  text-align: left;
  position: relative;
  font-size: xx-large;
  border-radius: 10px;
  text-shadow: -1px 1px 4px rgba(250,250,250,1);
  background: rgba(250,0,0,.65);
  font-style: oblique;
  display: inline-block;
  &> a {
    text-shadow: 0 0 5px #000;
    text-decoration: underline;
    color: #fff;
  }
  @media (max-width: $screen-sm){
    font-size: 5vw;
  }
}


