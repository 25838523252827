// Styles for Taxonomy
// -----------------------------------------------------------------------------
.field-slideshow-slide, .field-slideshow-slide a, .field-slideshow-slide {
  max-width: 100%;
  height: auto !important;
  width: 100% !important;
  text-align: center;
  img {
    margin: 0 auto;
  }
}

.field-slideshow {
  margin: 0 auto;
}

/* Term page stuff */

.taxonomy-term-description {
  display: inline-block;
  font-size: x-large;
  float: left;
  padding: 0 2%;
    width: 70%;
  @media (min-width: $screen-xlg) {
    font-size: 4vh;
    padding: 0 0 0 2%;
  }
  //@media  (max-width: $screen-xlg) and (min-width: $screen-lg) {
  @media  (max-width: $screen-xlg) and (min-width: $screen-sm) {
    font-size: 2.75vh;
    padding: 0 0 0 2%;
  }
  //@media (max-width: $screen-lg) and (min-width: $screen-md) {
  //  font-size: 2.35vh;
  //  padding: 0 0 0 2%;
  //}
  //@media (max-width: $screen-md) and (min-width: $screen-sm) {
  //  font-size: 2.5vh;
  //  padding: 0 0 0 2%;
  //}
  @media (max-width: $screen-sm) and (min-width: $screen-xs) {
    font-size: 2vh;
    width: 100%;
    padding: 0 0 0 2%;
  }
  @media (max-width: $screen-xs) {
    width: 100%;
    padding: 0 2%;
  }
}

.term-desc-block {
  width: 46%;
  float: left;
  height: 180px;
  border: 2px groove;
  margin: .5% 2%;
  padding: 0 .5%.5%;
  overflow: scroll;
}

.page-taxonomy-term {
  h1.title {
    text-align: center;
    font-style: oblique;
    font-weight: bolder;
  }
  .field-images {
    float: left;
    width: 29%;
    @media (max-width: $screen-sm) {
      width: 100%;
    }
  }
}

.products-listing__product-name {
  a {
    color: #555;
    text-decoration: none;
    text-shadow: 0 0 6px #fff;
    &:hover {
      color: #fbfd00;
      text-shadow: 0 0 6px #000;
    }
  }
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  padding: 1% 0 0;
  width: 100%;
  text-align: center;
  font-size: x-large;
  line-height: 100%;
  background: #c78e11;
  box-shadow: 1px -7px 20px #000;
  height: 65px;
  font-weight: 500;
}