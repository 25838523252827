// Styles for Menus and Navs
// -----------------------------------------------------------------------------

nav.navbar.navbar-default {
  background-color: $background-header-color;
  border-radius: 0px;
  border-style: none;
  margin: 0;
  padding: 0 2vh 3.5vh;
  @media screen and (max-width: 960px) {
    padding: 0 1.2vh !important;
  }
  a{
    color:rgba(176, 40, 40, 0.9);
    font-size: x-large;
  }
}

span.site-name {
  font-size: 2em;
  font-style: oblique;
  font-family: serif;
  @media (max-width: 1400px) {
    font-size: 1.25em;
    position: absolute;
    left: 75px;
    bottom: 1%;
  }
}

.collapse.navbar-collapse {
  float: right;
}

.logo img {
  height: 70px !important;
  margin: -12px;
  @media (max-width: 1400px) {
    height: 50px !important;
    margin:0;
  }
}

//Background white
.background-header {
  background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 70%, rgba(255,255,255,0.43) 87%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 70%,rgba(255,255,255,0.43) 87%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 70%,rgba(255,255,255,0.43) 87%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
}

button.navbar-toggle {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0;
}
